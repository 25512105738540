import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { JsonViewer } from '@textea/json-viewer'

import { 
  Typography, 
  Box, 
  Paper, 
  LinearProgress, 
  Button, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  IconButton,
  Popper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { lighten } from '@mui/material/styles';

import { useSnackbar } from 'notistack';

import axiosFundOps from '../rest-data-provider/axios';

import useTooltip from '../hooks/useTooltip';
import { assetsColumns } from './columns';
import AlertDialogYesNo from '../components/alertDialogYesNo';

import { MyVerticalTable } from '../components/verticalTable';
import { 
  stringifyError, 
  delay, 
  toISOStringWithTimezone, 
} from '../utils';
import config from '../config'
import { MyBreadcrumbs } from '../components/breadcrumbs';
import { SingleSelectTable } from '../components/selectTable'


export default function Funds() {

  const navigate = useNavigate();

  const fundsColumns = [
    {
      field: 'actions',
      type: 'actions',
      flex: 0.1,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <IconButton
            label='viewInvestorOrder'
            onClick={() => navigate(`/funds/${params.row.code}/portfolios/`)}
          >
            <QueryStatsIcon />
          </IconButton>
        ];
      },
    },
    { 
      field: 'code',
      headerName: 'Code',
      flex: 0.5,
    },
    { 
      field: 'name_en',
      headerName: 'Name (EN)',
      flex: 0.7,
    },
    { 
      field: 'denomination_asset_code',
      headerName: 'Denomination Asset',
      flex: 0.8,
    },
    { 
      field: 'initial_fund_price',
      headerName: 'Initial Price',
      flex: 0.4,
    },
    { 
      field: 'last_fund_price',
      headerName: 'Last Price',
      flex: 0.5,
    },
    { 
      field: 'last_gross_asset_value',
      headerName: 'Last GAV',
      flex: 0.6,
    },
    { 
      field: 'last_marking_datetime',
      headerName: 'Last Marking',
      flex: 0.7,
    },
    { 
      field: 'effective_from',
      headerName: 'Effective From',
      flex: 0.7,
      type: 'dateTime',
    },
    { 
      field: 'effective_to',
      headerName: 'Effective To',
      flex: 0.7,
      type: 'dateTime',
    },
  ];

  const { isLoading, isError, data, error } = useQuery({
    queryKey: [`funds`],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/funds');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      data?.forEach(
        (fund) => {
          fund.effective_from = fund.effective_from ? toISOStringWithTimezone(new Date(fund.effective_from)).slice(0, -6): null;
          fund.effective_to = fund.effective_to ? toISOStringWithTimezone(new Date(fund.effective_to)).slice(0, -6) : null;
          fund.last_marking_datetime = fund.last_marking_datetime ? toISOStringWithTimezone(new Date(fund.last_marking_datetime)).slice(0, -6) : null;
        }
      )
    }
  });

  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const table = isError ?
    stringifyError(error) :
    <DataGrid 
      columns={fundsColumns}
      rows={data ? data : []}
      autoHeight
      hideFooter
      loading={isLoading}
      density='compact'
      onRowClick={(params) => navigate(`/funds/${params.row.code}/`)}
      components={{
        LoadingOverlay: LinearProgress,
      }}
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
      getRowClassName={(params) => {
        const nowTime = new Date();
        const lastMarkingDatetime = new Date(params.row.last_marking_datetime);
        const deltaMarkingDatetime = Math.floor((nowTime - lastMarkingDatetime) / (1000*60))
        const className = deltaMarkingDatetime > config.MaxMarkingDeltaMinute ? "unupdate" : "update"
        return className
      }} 
    />

  return (
    <Box className='funds'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2}}>
        <Typography variant='h4'>Funds</Typography>
        <Button
          onClick={() => navigate('/funds/create/')}
          startIcon={<AddIcon />}
          size='small'
          disableElevation
        >
          Create Fund
        </Button>
      </Box>
      <Box
        sx={{ 
          flex: '1 1 auto',
          overflow: 'hidden',
          '& .unupdate': {
            bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9),
          }
        }}
      >
        {table}
        {popperElement}
      </Box>
    </Box>
  )
};

export function FundDetail() {
  const { fundCode } = useParams();
  const navigate = useNavigate();

  const [feeTypeIssue, setFeeTypeIssue] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, isError, data: fundData } = useQuery({
    queryKey: [`fund-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const { data: fundWallets } = useQuery({
    queryKey: [`fund-wallets-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/wallets`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const { data:lastFundNavData } = useQuery({
    queryKey: [`outstanding-nav-${fundCode}-latest`], 
      queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}/outstanding_nav`);
      return data;
    },  
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  async function handleIssueFee() {
    try {
      const response = await axiosFundOps.post(`/funds/${fundCode}/${feeTypeIssue}/issue`);
      enqueueSnackbar(`Issued ${feeTypeIssue}. Redirect to order detail page`, { variant: 'success' })
      await delay(500);
      navigate(`/orders/${response.data.id}`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(500);
    }
  };

  const { fees, ...fundDetail } = isLoading ? {fees: []} : isError ? {fees: []} : fundData;

  const fundDetailTable = MyVerticalTable(fundDetail);
  const managementFee = fees.filter((fee) => fee.type === 'management_fee').map(
    (fee) => {
      fee.id = String(fee.fund_id) + String(fee.created_at);
      fee.effective_from = fee.effective_from ? toISOStringWithTimezone(new Date(fee.effective_from)).slice(0, -6): null;
      fee.effective_to = fee.effective_to ? toISOStringWithTimezone(new Date(fee.effective_to)).slice(0, -6): null;
      return fee
    }
  );
  const performanceFee = fees.filter((fee) => fee.type === 'performance_fee').map(
      (fee) => {
        fee.id = String(fee.fund_id) + String(fee.created_at);
        fee.effective_from = fee.effective_from ? toISOStringWithTimezone(new Date(fee.effective_from)).slice(0, -6): null;
        fee.effective_to = fee.effective_to ? toISOStringWithTimezone(new Date(fee.effective_to)).slice(0, -6): null;
      return fee
    }
  );
  
  const feeIssueDialog = (
    <FeeIssueDialog
      lastFundNavData={lastFundNavData}
      openFeeType={feeTypeIssue}
      handleClose={() => setFeeTypeIssue("")}
      handleYes={handleIssueFee}
      feeInfo={feeTypeIssue === 'management_fee' ? managementFee : performanceFee}
    />
  )
  const fundWalletsTable = <FundWalletsTable data={fundWallets} />
  const managementFeeTable = <FeeTable data={managementFee ? managementFee : []} />;
  const performanceFeeTable = <FeeTable data={performanceFee ? performanceFee : []} />;

  return (
    <Box className='fund-detail'>
      {feeIssueDialog}
      <Grid container justifyContent='space-between' sx={{ mb: 2 }}>
        <MyBreadcrumbs/>
        <Button 
          onClick={() => navigate(`/funds/${fundCode}/portfolios/`)}
          size='small'
          startIcon={<QueryStatsIcon />}
          disableElevation
        >
          portfolio
        </Button>
      </Grid>
      <Box sx={{ mb: 3 }}>
        {fundDetailTable}
      </Box>
      <Box sx={{ mb: 3 }}>
        <Grid container justifyContent='space-between' sx={{ mb: 1 }}>
          <Typography variant='h6'>Wallets</Typography>
          <Button 
            size='small' 
            onClick={() => navigate(`/funds/${fundCode}/wallet/create/`)}
            startIcon={<AddIcon />}
          >
            Create Wallets
          </Button>
        </Grid>
        {fundWalletsTable}
      </Box>
      <Box sx={{ mb: 3 }}>
        <Grid container justifyContent='space-between' sx={{ mb: 1 }}>
          <Typography variant='h6'>Management Fees</Typography>
          <Box>
            <Button 
              size='small' 
              onClick={() => setFeeTypeIssue('management_fee')} 
              sx={{ mr:1 }} 
              startIcon={<CurrencyExchangeIcon />}
            >
              Issue Management Fee
            </Button>
            <Button
              size='small'
              onClick={() => navigate(`/funds/${fundCode}/management_fee/create/`)}
              startIcon={<AddIcon />}
              sx={{ mr: 1 }}
            >
              update management fee
            </Button>
          </Box>
        </Grid>
        <Box>
          {managementFeeTable}
        </Box>
      </Box>
      <Box>
        <Grid container justifyContent='space-between' sx={{ mb: 1 }}>
          <Typography variant='h6'>Performance Fees</Typography>
          <Box>
            <Button size='small' onClick={() => setFeeTypeIssue('performance_fee')} sx={{ mr:1 }} startIcon={<CurrencyExchangeIcon />}> 
              Issue Performance Fee
            </Button>
            <Button
              size='small'
              onClick={() => navigate(`/funds/${fundCode}/performance_fee/create/`)}
              startIcon={<AddIcon />}
              sx={{ mr: 1 }}
            >
              update performance fee
            </Button>
          </Box>
        </Grid>
        <Box>
          {performanceFeeTable}
        </Box>
      </Box>
    </Box>
  )
};

export function FundCreate() {
  
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [ formValues, setFormValues ] = useState({
      code: "",
      name_en: "",
      name_th: "",
      denomination_asset_id: null,
      initial_fund_price: 100,
      effective_from: toISOStringWithTimezone(new Date()).slice(0, -6),
      effective_to: null
  });

  const { data:assetsData } = useQuery({
    queryKey: ['assets-strategy_protocol=wallet'], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/assets', {params: {strategy_protocol: 'wallet'}});
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const response = await axiosFundOps.post(`/funds/create`, formValues);
      enqueueSnackbar(`Fund ${response.data.code} created. Redirect to detail page`, { variant: 'success' })
      await delay(1000);
      navigate(`/funds/${response.data.code}`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const denomAssetSelectElement = SingleSelectTable(assetsData, assetsColumns, formValues.denomination_asset_id, ((value) => setFormValues({...formValues, 'denomination_asset_id': value})));

  const formInput = (
    <>
      <Box sx={{ mb:2 }}>
        <TextField
          name="code"
          label="Fund Code"
          value={formValues.code}
          size="small"
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={{ display: 'flex',mb:2 }}>
        <TextField
          name="name_en"
          label="Fund Name (EN)"
          value={formValues.name_en}
          size="small"
          fullWidth
          onChange={handleInputChange}
          sx={{ pr:2 }}
        />
        <TextField
          name="name_th"
          label="Fund Name (TH)"
          value={formValues.name_th}
          size="small"
          fullWidth
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={{ mb:2 }}>
        <TextField
          name="initial_fund_price"
          label="Initial Fund Price"
          value={formValues.initial_fund_price}
          type="number"
          onWheel={(e) => e.target.blur()}
          size="small"
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={{ mb:2 }}>
        Denomination Asset:
        {denomAssetSelectElement}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr:2, pt:1.1 }}>Effective</Typography>
        <Box sx={{ mr:2 }}>
          <TextField
            name="effective_from"
            label="From"
            value={formValues.effective_from}
            type='datetime-local'
            size="small"
            onChange={handleInputChange}
          />
        </Box>
        <Box>
          <TextField
            name="effective_to"
            label="To"
            defaultValue="null"
            disabled
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
    </>
  );

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create a new fund from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  return (
    <Box className='create-fund'>
      <Box sx={{ mb: 2 }}>
        <MyBreadcrumbs/>
      </Box>
      <Box sx={{ mb: 2 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
};

export function ManagementFeeCreate() {
  
  const { fundCode } = useParams();
  const [ feeParams, setFeeParams ] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [ formValues, setFormValues ] = useState({
    recipient_investor_account_code: null,
    fee_calculation_hash: null,
    fee_params: null,
    effective_from: null,
    effective_to: null,
  });
  
  const { data:feesOptions } = useQuery({
    queryKey:['management-fee-options'], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/fees`, { params: { fee_type: 'management_fee' } });
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const { data:investorAccountsOptions } = useQuery({
    queryKey:[`investor-accounts-options-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/investor_accounts', { params: { fund_code:fundCode }});
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const { data:fundData } = useQuery({
    queryKey:[`fund-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  
  useEffect(() => {
    const effective_from = new Date(fundData?.last_marking_datetime ? fundData?.last_marking_datetime : fundData?.effective_from);
    
    setFormValues({
      ...formValues,
      effective_from:toISOStringWithTimezone(effective_from).slice(0, -6)
    });      
  }, [fundData])


  useEffect(() => {
    const selectedFeeParam = feesOptions
      ?.filter((feeChoice) => feeChoice?.fee_calculation_hash === formValues?.fee_calculation_hash)?.[0]?.params

    if (selectedFeeParam) {
      const paramName = Object.keys(selectedFeeParam);
      setFeeParams(
        Object.fromEntries(paramName.map((param) => [param, selectedFeeParam[param].value ]))
      )
    }
  }, [feesOptions]);

  function handleInputChange(e) {
    const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value === "" ? null : value,
      });      
  };

  function handleInputFeeParamsChange(e) {
    const { name, value } = e.target;
    const feeType = feesOptions
      ?.filter((feeChoice) => feeChoice?.fee_calculation_hash === formValues?.fee_calculation_hash)?.[0]
      ?.params[name]?.type
    const castedValue = feeType === 'float' ? parseFloat(value) : feeType === 'int' ? parseInt(value) : value;
    setFeeParams({
      ...feeParams,
      [name]: castedValue === "" ? null : castedValue,
    });      
  };

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const payload = { ...formValues, fee_params:feeParams }
      await axiosFundOps.post(`/funds/${fundCode}/management_fee/create`, payload);
      enqueueSnackbar(`Management fee is updated. Redirect to fund ${fundCode} detail page`, { variant: 'success' })
      await delay(500);
      navigate(`/funds/${fundCode}`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(500);
      setIsSubmitAwaiting(false);
    }
  };

  const feeParamsForm = (
    <>
      <Box>
        {Object.entries(formValues?.fee_calculation_hash ? feesOptions
          ?.filter((feeChoice) => feeChoice?.fee_calculation_hash === formValues?.fee_calculation_hash)?.[0]?.params
        : {})?.map((feeParam) => {
          const [paramName, paramInfo] = feeParam;
          return (
          <TextField 
            name={paramName}
            label={paramName}
            key={paramName}
            value={feeParams[paramName]}
            onChange={handleInputFeeParamsChange}
            type={["float", "int"].includes(paramInfo.type) ? 'number' : 'text' }
            onWheel={(e) => e.target.blur()}
            fullWidth
            sx={{ mb:2 }}
            InputLabelProps={{
              shrink: true,
            }}
          />)
        })}
        </Box>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Typography sx={{ mr:2, pt:1.1 }}>Effective</Typography>
          <Box sx={{ mb:2 }}>
            <TextField
              name="effective_from"
              label="From"
              value={formValues.effective_from}
              type='datetime-local'
              size="small"
              disabled
              sx={{ mr: 1 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              name="effective_to"
              label="To"
              defaultValue="null"
              disabled
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
    </>
  );

  const selectedInvestorAccount = investorAccountsOptions?.filter((investor) => investor.code === formValues.recipient_investor_account_code)?.[0];

  const recipientInvestorAccountDetail = (
    <Box display={ selectedInvestorAccount ? 'block'  : 'none' }>
      <Typography variant='subtitle1' sx={{ my:2 }}>Investor Account Detail</Typography>
      {MyVerticalTable(selectedInvestorAccount ? selectedInvestorAccount : {})}
    </Box>
  );

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create management fee from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  const recipientInvestorAccountForm = (
    <Box>
      <TextField
        name="recipient_investor_account_code"
        label="Investor Account Code"
        values={formValues.recipient_investor_account_code}
        size="small"
        fullWidth
        onBlur={handleInputChange}
        sx={{ 
          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline" : { borderColor: selectedInvestorAccount ? "success.light" : "warning.main" }
        }}
      />
      {recipientInvestorAccountDetail}
    </Box>
  );

  const formInput = (
    <>
      <Box sx={{ mb:3 }}>
        <Typography variant='h6' sx={{ mb: 1 }}>Fee Calculation</Typography>
        <Box>
          <Box sx={{ mb:2 }}>
            <FormControl size='small' sx={{ minWidth:'200px' }}>
              <InputLabel>Fee Calculation Name</InputLabel>
              <Select
                value={formValues.fee_calculation_hash}
                name="fee_calculation_hash"
                label="Fee Calculation Hash"
                fullWidth
                onChange={handleInputChange}
              >
                {feesOptions?.map((feeChoice) => <MenuItem key={feeChoice?.fee_calculation_hash} value={feeChoice?.fee_calculation_hash}>{feeChoice?.fee_calculation_name}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <TextField
              name="fee_calculation"
              label="Fee Calculation Code"
              value={feesOptions?.filter((feeChoice) => feeChoice?.fee_calculation_hash === formValues?.fee_calculation_hash)?.[0]?.fee_calculation}
              disabled
              fullWidth
              multiline
              minRows={2}
              onChange={handleInputChange}
              inputProps={{
                style: {fontFamily: 'monospace'}
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb:3 }}>
        <Typography variant='h6' sx={{ mb:1 }}>Fee Parameters</Typography>
        {feeParamsForm}
      </Box>
      <Box sx={{ mb:3 }}>
        <Typography variant='h6' sx={{ mb:1 }}>Recipient Investor Account</Typography>
        {recipientInvestorAccountForm}
      </Box>
    </>
  );


  return (
    <Box className='management-fee-create'>
      <Box sx={{ mb:3 }}>
        <MyBreadcrumbs ignoreParents={['management_fee']} />
      </Box>
      <Box  sx={{ mb: 3 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
};
export function PerformanceFeeCreate() {
  
  const { fundCode } = useParams();
  const [ feeParams, setFeeParams ] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [ formValues, setFormValues ] = useState({
    recipient_investor_account_code: null,
    fee_calculation_hash: null,
    fee_params: null,
    effective_from: null,
    effective_to: null,
  });
  
  const { data:feesOptions } = useQuery({
    queryKey:['performance-fee-options'], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/fees`, { params: { fee_type: 'performance_fee' } });
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const { data:investorAccountsOptions } = useQuery({
    queryKey:[`investor-accounts-options-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/investor_accounts', { params: { fund_code:fundCode }});
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  const { data:fundData } = useQuery({
    queryKey:[`fund-${fundCode}`], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get(`/funds/${fundCode}`);
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
  
  useEffect(() => {
    const effective_from = new Date(fundData?.last_marking_datetime ? fundData?.last_marking_datetime : fundData?.effective_from);
    
    setFormValues({
      ...formValues,
      effective_from:toISOStringWithTimezone(effective_from).slice(0, -6)
    });      
  }, [fundData])


  useEffect(() => {
    const selectedFeeParam = feesOptions
      ?.filter((feeChoice) => feeChoice?.fee_calculation_hash === formValues?.fee_calculation_hash)?.[0]?.params

    if (selectedFeeParam) {
      const paramName = Object.keys(selectedFeeParam);
      setFeeParams(
        Object.fromEntries(paramName.map((param) => [param, selectedFeeParam[param].value ]))
      )
    }
  }, [feesOptions]);

  function handleInputChange(e) {
    const { name, value } = e.target;
      setFormValues({
        ...formValues,
        [name]: value === "" ? null : value,
      });      
  };

  function handleInputFeeParamsChange(e) {
    const { name, value } = e.target;
    const feeType = feesOptions
      ?.filter((feeChoice) => feeChoice?.fee_calculation_hash === formValues?.fee_calculation_hash)?.[0]
      ?.params[name]?.type
    const castedValue = feeType === 'float' ? parseFloat(value) : feeType === 'int' ? parseInt(value) : value;
    setFeeParams({
      ...feeParams,
      [name]: castedValue === "" ? null : castedValue,
    });      
  };

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const payload = { ...formValues, fee_params:feeParams }
      await axiosFundOps.post(`/funds/${fundCode}/performance_fee/create`, payload);
      enqueueSnackbar(`Performance fee is updated. Redirect to fund ${fundCode} detail page`, { variant: 'success' })
      await delay(500);
      navigate(`/funds/${fundCode}`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(500);
      setIsSubmitAwaiting(false);
    }
  };

  const feeParamsForm = (
    <>
      <Box>
        {Object.entries(formValues?.fee_calculation_hash ? feesOptions
          ?.filter((feeChoice) => feeChoice?.fee_calculation_hash === formValues?.fee_calculation_hash)?.[0]?.params
        : {})?.map((feeParam) => {
          const [paramName, paramInfo] = feeParam;
          return (
          <TextField 
            name={paramName}
            label={paramName}
            key={paramName}
            value={feeParams[paramName]}
            onChange={handleInputFeeParamsChange}
            type={["float", "int"].includes(paramInfo.type) ? 'number' : 'text' }
            onWheel={(e) => e.target.blur()}
            fullWidth
            sx={{ mb:2 }}
            InputLabelProps={{
              shrink: true,
            }}
          />)
        })}
        </Box>
        <Box sx={{ display: 'flex', mb: 2 }}>
          <Typography sx={{ mr:2, pt:1.1 }}>Effective</Typography>
          <Box sx={{ mb:2 }}>
            <TextField
              name="effective_from"
              label="From"
              value={formValues.effective_from}
              type='datetime-local'
              size="small"
              disabled
              sx={{ mr: 1 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              name="effective_to"
              label="To"
              defaultValue="null"
              disabled
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
    </>
  );

  const selectedInvestorAccount = investorAccountsOptions?.filter((investor) => investor.code === formValues.recipient_investor_account_code)?.[0];

  const recipientInvestorAccountDetail = (
    <Box display={ selectedInvestorAccount ? 'block'  : 'none' }>
      <Typography variant='subtitle1' sx={{ my:2 }}>Investor Account Detail</Typography>
      {MyVerticalTable(selectedInvestorAccount ? selectedInvestorAccount : {})}
    </Box>
  );

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create performance fee from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  const recipientInvestorAccountForm = (
    <Box>
      <TextField
        name="recipient_investor_account_code"
        label="Investor Account Code"
        values={formValues.recipient_investor_account_code}
        size="small"
        fullWidth
        onBlur={handleInputChange}
        sx={{ 
          ".css-1d3z3hw-MuiOutlinedInput-notchedOutline" : { borderColor: selectedInvestorAccount ? "success.light" : "warning.main" }
        }}
      />
      {recipientInvestorAccountDetail}
    </Box>
  );

  const formInput = (
    <>
      <Box sx={{ mb:3 }}>
        <Typography variant='h6' sx={{ mb: 1 }}>Fee Calculation</Typography>
        <Box>
          <Box sx={{ mb:2 }}>
            <FormControl size='small' sx={{ minWidth:'200px' }}>
              <InputLabel>Fee Calculation Name</InputLabel>
              <Select
                value={formValues.fee_calculation_hash}
                name="fee_calculation_hash"
                label="Fee Calculation Hash"
                fullWidth
                onChange={handleInputChange}
              >
                {feesOptions?.map((feeChoice) => <MenuItem key={feeChoice?.fee_calculation_hash} value={feeChoice?.fee_calculation_hash}>{feeChoice?.fee_calculation_name}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <TextField
              name="fee_calculation"
              label="Fee Calculation Code"
              value={feesOptions?.filter((feeChoice) => feeChoice?.fee_calculation_hash === formValues?.fee_calculation_hash)?.[0]?.fee_calculation}
              disabled
              fullWidth
              multiline
              minRows={2}
              onChange={handleInputChange}
              inputProps={{
                style: {fontFamily: 'monospace'}
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ mb:3 }}>
        <Typography variant='h6' sx={{ mb:1 }}>Fee Parameters</Typography>
        {feeParamsForm}
      </Box>
      <Box sx={{ mb:3 }}>
        <Typography variant='h6' sx={{ mb:1 }}>Recipient Investor Account</Typography>
        {recipientInvestorAccountForm}
      </Box>
    </>
  );


  return (
    <Box className='performance-fee-create'>
      <Box sx={{ mb:3 }}>
        <MyBreadcrumbs ignoreParents={['performance_fee']} />
      </Box>
      <Box  sx={{ mb: 3 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
};

function FeeTable( { data } ) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cellValue, setCellValue] = useState([]);

  function handleCalculationPopperOpen(event, value) {
      setCellValue(value);
      setAnchorEl(event?.currentTarget);
    };
  
  function handleCalculationPopperClose() {
    setAnchorEl(null);
  };

  const popperElement = (
    <Popper
      placement="top-start"
      sx={{
        pointerEvents: 'none'
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleCalculationPopperClose}
    >
      <Paper sx={{ p: 1 }}>
        <pre>
          {cellValue}
        </pre>
      </Paper>
    </Popper>
  );

  const fundFeeColumns = [
    { 
      field: 'effective_from',
      headerName: 'Effective From',
      flex: 1,
      type: 'dateTime',
    },
    { 
      field: 'effective_to',
      headerName: 'Effective To',
      flex: 1,
      type: 'dateTime',
    },
    { 
      field: 'fee_calculation_name',
      headerName: 'Fee Calculation',
      flex: 1.3,
      renderCell: (cellValues) => {
        return (
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <Typography
              onMouseEnter={(event) => handleCalculationPopperOpen(event, cellValues.row.fee_calculation)}
              onMouseLeave={handleCalculationPopperClose}
            >
              {cellValues.value}
            </Typography>
            {popperElement}
          </Box>
      )
      }
    },
    { 
      field: 'fee_calculation_hash',
      headerName: 'Fee Calculation Hash',
      flex: 1,
    },
    { 
      field: 'fee_params',
      headerName: 'Fee Params',
      flex: 1.5,
      renderCell: (cellValues) => (
        <Box sx={{ py:1 }}>
          <JsonViewer value={cellValues.value} enableClipboard={false} defaultInspectDepth={0} displayDataTypes={false} rootName={false} />
        </Box>
      )
    },
    { 
      field: 'fee_params_hash',
      headerName: 'Fee Params Hash',
      flex: 1,
    },
    { 
      field: 'recipient_investor_account_code',
      headerName: 'Recipient Investor Account Code',
      flex: 1.5,
      renderCell: (cellValues) => (
        <Typography 
          components={Link} 
          onClick={() => {navigate(`/investors/${cellValues.row.recipient_investor_id}/accounts/${cellValues.value}`);}}
          sx={{ '&:hover': { cursor:'pointer' }, textDecoration:'underline', color:'primary.main' }}
        >
          {cellValues.value}
        </Typography>
      )
    },
  ];

  return (
    <Box sx={{          
      '& .ineffective': {
      bgcolor: (theme) => lighten(theme.palette.warning.main, 0.9)
      },
    }}>
      <DataGrid 
        columns={fundFeeColumns}
        rows={data}
        autoHeight
        hideFooter
        disableSelectionOnClick
        density='compact'
        getRowHeight={() => 'auto'}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        getRowClassName={(params) => params.row.effective_to ? 'ineffective' : 'effective'}
      />
    </Box>
  )
};

function FeeIssueDialog({ 
  lastFundNavData,
  openFeeType,
  handleClose,
  handleYes,
  feeInfo
 }) {
  const feeTableField = openFeeType === 'management_fee' ? 
   [
      'last_management_fee_issuance_datetime',
      'last_management_fee_issuance_elapse',
      'units_accrued_management_fee'
   ] : 
   [
      'last_performance_fee_issuance_datetime',
      'last_performance_fee_issuance_high_water_mark',
      'units_accrued_performance_fee'
   ]
  const feeType = openFeeType.replace("_", " ")
  const dialogTitle = `Are you sure you want to issue ${feeType}?`
  const effectiveFee = feeInfo.at(-1);

  const feeTableInfo = feeTableField
    ?.filter(key => key in (lastFundNavData !== undefined ? lastFundNavData : {}))
    .reduce((obj2, key) => (obj2[key] = lastFundNavData[key], obj2), {});

  feeTableInfo['fund_reference_datetime'] = lastFundNavData?.datetime;
  feeTableInfo['recipient_investor_account_code'] = effectiveFee?.recipient_investor_account_code;

  const submitTableInfo = MyVerticalTable(feeTableInfo);
  
  const feeUnits = openFeeType === 'management_fee' ? lastFundNavData?.units_accrued_management_fee : lastFundNavData?.units_accrued_performance_fee;

  const dialogContent = (
    <>
      <Typography sx={{ mb: 2 }}>
        The following information is being referenced for issuing {feeType} units. 
        In summary, <strong>{feeUnits}</strong> units of accrued {feeType} will be issued to <strong>{effectiveFee?.recipient_investor_account_code}</strong>.
      </Typography>
      <Paper>
        {submitTableInfo}
      </Paper>
    </>
  )

  return (
    <Dialog
      open={!!openFeeType}
      onClose={handleClose}
      fullWidth={true}
      maxWidth='lg'
      transitionDuration={0.3}
    >
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        {dialogContent}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleClose} 
          color='error' 
          variant='outlined'
        >
          No
        </Button>
        <Button 
          onClick={() => {handleYes(); handleClose(); }} 
          autoFocus 
          variant='contained'
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function FundWalletsTable( { data } ){
  const fundWalletsColumns = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 5
    },
    { 
      field: 'wallet_name',
      headerName: 'Wallet Name',
      flex: 1,
    },
    { 
      field: 'wallet_chain',
      headerName: 'Chain',
      flex: 0.5,
    },
    { 
      field: 'custodial_account_name',
      headerName: 'Custodial Account',
      flex: 1,
    },
    { 
      field: 'wallet_address',
      headerName: 'Address',
      flex: 1,
    },
  ];
  const {handlePopperOpen, handlePopperClose, popperElement} = useTooltip(data);

  const table = (
    <DataGrid 
      columns={fundWalletsColumns}
      rows={data ? data : []}
      autoHeight
      hideFooter
      disableSelectionOnClick
      density='compact'
      componentsProps={{
        cell: {
          onMouseEnter: handlePopperOpen,
          onMouseLeave: handlePopperClose,
        },
      }}
      components={{
        LoadingOverlay: LinearProgress,
      }}
    />
  )

  return (
    <>
      {table}
      {popperElement}
    </>
  )
};

export function FundWalletCreate() {
  
  const { fundCode } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
  const [ formValues, setFormValues ] = useState({
    "custodial_account_code": '',
    "chain_name": '',
    "fund_code": fundCode,
    "wallet_name": "",
    "wallet_address": "",
    "wallet_access_key": null,
    "wallet_access_secret": null
  });

  const { data: chainsData } = useQuery({
    queryKey: ['chains'],
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/chains');
      return data;
    },
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });

  const { data:custodial_accounts_data } = useQuery({
    queryKey: ['custodial-accounts'], 
    queryFn: async () => {
      const { data } = await axiosFundOps.get('/custodial_accounts');
      return data;
    }, 
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  async function handleSubmit(){
    try {
      setIsSubmitAwaiting(true);
      const response = await axiosFundOps.post(`/wallets/create`, formValues);
      enqueueSnackbar(`wallet ${response.data.wallet_name} created. Redirect to detail page`, { variant: 'success' })
      await delay(1000);
      navigate(`/funds/${fundCode}`);

    } catch (err) {
      enqueueSnackbar(stringifyError(err), { variant: 'error' })

    } finally {
      await delay(5000);
      setIsSubmitAwaiting(false);
    }
  };

  function handleInputChange(e) {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const buttons = (
    <>
      <AlertDialogYesNo 
        open={open === 'submit'}
        handleClose={() => setOpen(false)}
        handleOpen={() => setOpen(true)}
        handleYes={handleSubmit}
        dialogTitle='Are you sure you want to create a new wallet from this form?'
      />
      <Button
        onClick={() => setOpen('submit')}
        disabled={isSubmitAwaiting}
        startIcon={<SaveIcon />}
        variant='contained'
        size='small'
      >
        Submit
      </Button>
    </>
  );

  const formInput = (
    <>
      <Box>
        <FormControl size='small' sx={{ mb: 2, minWidth: '150px', mr: 2 }}>
          <InputLabel>Chain</InputLabel>
          <Select
            value={formValues.chain_name}
            name="chain_name"
            label="Chain"
            fullWidth
            onChange={(e) => {
                handleInputChange(e);
            }}
          >
            {
              chainsData?.map((chain) => {
                return <MenuItem value={chain.name} key={chain.name}>{chain.name}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <FormControl size='small' sx={{ mb: 2, minWidth: '170px', mr: 2 }}>
          <InputLabel>Custodial Account</InputLabel>
          <Select
            value={formValues.custodial_account_code}
            name="custodial_account_code"
            label="Custodial Account"
            fullWidth
            onChange={(e) => {
                handleInputChange(e);
            }}
          >
            {
              custodial_accounts_data?.map((custodial_account) => {
                return <MenuItem value={custodial_account.code} key={custodial_account.code}>{custodial_account.code}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', mb:2 }}>
        <TextField
          name="wallet_name"
          label="Wallet Name"
          value={formValues.wallet_name}
          size="small"
          fullWidth
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={{ display: 'flex', mb:2 }}>
        <TextField
          name="wallet_address"
          label="Wallet Address / Account ID"
          value={formValues.wallet_address}
          size="small"
          fullWidth
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={{ display: 'flex', mb:2 }}>
        <TextField
          name="wallet_access_key"
          label="Wallet Access Key"
          value={formValues.wallet_access_key}
          size="small"
          fullWidth
          onChange={handleInputChange}
        />
      </Box>
      <Box sx={{ display: 'flex', mb:2 }}>
        <TextField
          name="wallet_access_secret"
          label="Wallet Access Secret ␣ Passphrase"
          value={formValues.wallet_access_secret}
          size="small"
          fullWidth
          onChange={handleInputChange}
        />
      </Box>
    </>
  );

  return (
    <Box className='fund-wallet-create'>
      <Box sx={{ mb:2 }}>
        <MyBreadcrumbs ignoreParents={['wallet']} />
      </Box>
      <Box sx={{ mb: 3 }}>
        {formInput}
      </Box>
      <Grid container justifyContent="flex-end">
        {buttons}
      </Grid>
    </Box>
  )
}